.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 120px;
  margin-bottom: var(--gap);
  filter: grayscale(100%);
  opacity: 0.15;
}

.back {
  text-decoration: underline;
}
